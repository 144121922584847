// React Dependencies
import React, { useEffect, useState } from 'react';

// External Dependencies
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: '0 !important',
      margin:  '0 !important',
    },
    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px !important',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingLeft:  0,
      paddingRight: 0,
      margin:       0,
    },
    '& .MuiIconButton-root': {
      padding: 5,
    },
  },
});

const MaterialDatePicker = ({ label, value, onChange, minDate, style, readOnly, variant, format, disabled, inputStyle, className, clearable }) => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState();

    useEffect(() => {
      setSelectedDate(value || null);
    }, [value]);

    const handleDateChange = (date) => {
      const momentDate = moment(date);

      if (momentDate.isValid()) {
        setSelectedDate(date);
        onChange(date);
      } else if (date === null) {
        setSelectedDate(null);
        onChange(null);
      } else {
        const fallbackDate = minDate || new Date();

        setSelectedDate(fallbackDate);
        onChange(fallbackDate);
      }
    };

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          InputProps={{
            endAdornment: (
              <IconButton onClick={() => handleDateChange(null)} disabled={disabled}>
                <ClearIcon />
              </IconButton>
            ),
            style: inputStyle,
          }}
          InputAdornmentProps={{
            position: clearable ? 'start' : 'end',
          }}
          className={`${classes.root} ${className}`}
          disableToolbar
          autoOk
          inputVariant={variant}
          variant="inline"
          format={format}
          margin="normal"
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          readOnly={readOnly}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          minDate={minDate}
          onKeyDown={(e) => e.preventDefault()}
          style={style}
          inputProps={{
            style: inputStyle,
          }}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    );
};

MaterialDatePicker.propTypes = {
  label:      PropTypes.string,
  value:      PropTypes.shape(Object),
  onChange:   PropTypes.func.isRequired,
  format:     PropTypes.string,
  variant:    PropTypes.string,
  disabled:   PropTypes.bool,
  readOnly:   PropTypes.bool,
  minDate:    PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.bool]),
  style:      PropTypes.shape(Object),
  inputStyle: PropTypes.shape(Object),
  className:  PropTypes.string,
  clearable:  PropTypes.bool,
};

MaterialDatePicker.defaultProps = {
  label:      '',
  value:      null,
  format:     'dd/MM/yyyy',
  variant:    'inline',
  readOnly:   false,
  disabled:   false,
  minDate:    false,
  clearable:  false,
  style:      {},
  inputStyle: {},
  className:  '',
};

export default MaterialDatePicker;
