/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import { IconButton, Button, Stepper, StepLabel, Step, Typography, DialogTitle, DialogContent, DialogActions, Dialog }
from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Loading from '../../../components/Loading';
import Colours from '../../../styles/colours';
import Preview from './Preview';
import Details from './Details/Details';
import Locations from './Locations/Locations';
import ApiService from '../../../services/apiService';
import reviewAction from '../../../actions/review';
import { Error, Status } from '../../../common/constants';
import { IsAdminOrContributor, IfTrueThrowError, IsArray } from '../../../common/checks';
import { IsEmpty, Trim } from '../../../common/util';
import Tooltip from '../../../components/Common/ToolTip';
import Icons from '../../../components/Common/Icons';
import Media from './Media';
import Launch from './Launch';
import SubscriptionLimitModal from '../../../components/SubscriptionLimitModal';

const useStyles = makeStyles(() => ({
  root: {
    width:   '100%',
    padding: 0,
  },
  label: {
    margin:     '0 !important',
    cursor:     'pointer',
    fontSize:   14,
    lineHeight: '40px',
  },
}));

const DesktopUpdate = (props) => {
  const stepperClasses = useStyles();
  const [currentReviewStatus, setCurrentReviewStatus] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [placesAndLocations, setPlacesAndLocations] = useState([]);
  const [planModal, setPlanModal] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [steps] = useState(['1. Details', '2. Locations', '3. Media', '4. Launch']);
  const [dialogContent, setDialogContent] = useState({
    title:   'Save to Live',
    message: 'Review cannot be updated back to Draft once it is Live, proceed?',
  });

  const [tags, setTags] = useState([]);
  const [cuisineTags, setCuisineTags] = useState([]);

  const fetchReview = async (id) => {
    try {
      const review = await ApiService.getReview(id, { pageId: props.review.pageId });
      setCurrentReviewStatus(review.status);

      const reviewTags = IsArray(review.tags) ? review.tags : [];
      const reviewCuisineTags = IsArray(review.cuisineTags) ? review.cuisineTags : [];
      setTags(reviewTags);
      setCuisineTags(reviewCuisineTags);

      const reviewPlaces = IsArray(review.reviewPlaces) ? review.reviewPlaces : [];
      const reviewLocations = IsArray(review.reviewLocations) ? review.reviewLocations : [];

      setPlacesAndLocations(_.concat(reviewPlaces, reviewLocations) || '');
      props.dispatchReviewUpdate({
        currentReviewStatus: review.status,
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (props.review.id) {
      fetchReview(props.review.id);
    }
  }, [props.review.id]);

  useEffect(() => {
    setAccountType(props.page.plan);
  }, [props]);

  const checkReviewData = (review) => {
    IfTrueThrowError(review.status === Status.Live && IsEmpty(Trim(review.title)), 'Title is required');
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const r = props.review;

      // Standardize to pass Singapore timezone to the server
      const startDate = r.reviewDate ? new Date(r.reviewDate) : null;
      const reviewStartDate = startDate ? moment.tz(startDate, 'Asia/Singapore').startOf('day').format('YYYY-MM-DD HH:mm:ss Z') : null;

      const expireDate = r.expireDate ? new Date(r.expireDate) : null;
      const reviewExpireDate = expireDate ? moment.tz(expireDate, 'Asia/Singapore').endOf('day').format('YYYY-MM-DD HH:mm:ss Z') : null;

      // perform checks
      checkReviewData(r);

      let reviewStatus = r.status;
      if (props.page.status !== Status.Pending && r.status === Status.Deleted) {
        reviewStatus = Status.Draft;
      } else if (props.page.status === Status.Pending) {
        reviewStatus = Status.Pending;
      }

      const payload = {
        title:           r.title,
        content:         r.content,
        status:          reviewStatus,
        images:          r.images,
        videos:          r.videos,
        pageId:          r.pageId,
        reviewDate:      reviewStartDate,
        expireDate:      reviewExpireDate,
        isFnb:           r.isFnb,
        url:             r.url ? String(r.url).trim() : '',
        tags:            r.tags,
        cuisineTags:     r.cuisineTags,
        reviewPlaces:    r.reviewPlaces,
        reviewLocations: r.reviewLocations,
      };

      // eslint-disable-next-line react/prop-types
      await ApiService.updateReview(props.match.params.reviewId, payload);
      props.dispatchReviewUpdate({ currentReviewStatus: payload.status });
      toast.success('Review updated');

      setCurrentReviewStatus(payload.status);
      setActiveStep(0);

      props.getReview();
    } catch (err) {
      if (err.message === Error.MaxNumberPublishedReview) {
        setPlanModal(true);
      } else {
        toast.error(err.message);
      }
    } finally {
      setConfirmPopup(false);
      setLoading(false);
    }
  };

  const handleUpdateConfirmation = async (e) => {
    e.preventDefault();
    try {
      const propReview = props.review;

      // check current review status
      const review = await ApiService.getReview(propReview.id, { pageId: propReview.pageId });

      setConfirmPopup(true);
      if (review.status === Status.Draft && propReview.status === Status.Live) {
        setDialogContent({
          title:   'Save as Live',
          message: `Review cannot be updated back to ${review.status} once it is Live, proceed?`,
        });
      } else {
        setConfirmPopup(false);
        handleUpdate();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (<Details {...props} otherTags={tags} cuisineTags={cuisineTags} />);
      case 1:
        return (
          <Locations
            {...props}
            placesLocations={placesAndLocations}
            onChangeSetTags={(reviewTags, reviewCuisineTags) => { setTags(reviewTags); setCuisineTags(reviewCuisineTags); }}
          />
        );
      case 2:
        return (<Media {...props} />);
      case 3:
        return (<Launch {...props} />);
      default:
    }
  }

  return (
    <>
      <div className="container-fluid d-flex flex-row align-items-start justify-content-between px-0" style={{ position: 'relative' }}>
        <div className="col-lg-9 col-md-9 px-4 pt-1 row flex-column">
          <div className="col-12 d-flex align-items-center">
            <div className="d-inline-flex align-items-center">
              <IconButton style={{ width: 50, height: 50 }} onClick={() => { props.history.goBack(); }}>
                <Icons.ArrowBack colour={Colours.Black} />
              </IconButton>
              <Typography variant="body1" className="ml-2" color="inherit" style={{ color: Colours.Gray }}>Edit Review</Typography>
            </div>

            <div className="ml-auto d-inline-flex align-items-center">
              <Typography variant="body1" style={{ color: Colours.Gray1 }}>
                Status:&nbsp;
                <span
                  className="badge badge-pill badge-secondary py-2 px-3 mr-1"
                  style={{
                    background:
                      (currentReviewStatus === Status.Live)
                        ? Colours.Green
                        : Colours.Gray1,
                  }}
                >
                  { currentReviewStatus }
                </span>
              </Typography>
              <Tooltip title={`Review's status`}>
                <Icons.InfoOutlined colour={Colours.Gray1} fontSize="1.3rem" />
              </Tooltip>
            </div>
          </div>

          <div className="col-12 mt-1 mb-2 d-flex align-items-center justify-content-end">
            <div className="w-100 py-3 px-3 mx-5 align-items-center text-center" style={{ background: Colours.White, color: Colours.Gray2, border: '1px solid', borderColor: Colours.Gray3, borderRadius: 100 }}>
              <div className="container">
                <div className="row">

                  <Stepper classes={{ root: stepperClasses.root }} nonLinear activeStep={activeStep} connector={<></>} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          onClick={() => { setActiveStep(index); }}
                          icon={false}
                          classes={{ label: stepperClasses.label }}
                        >
                          { label }
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                </div>
              </div>
            </div>
          </div>

          <div className="col-12">
            <Typography className="ml-4" variant="caption" color="inherit" style={{ color: Colours.Red }}>* required</Typography>
          </div>

          <div className="col-12 d-block mx-auto mt-2">
            {getStepContent(activeStep)}
          </div>

          { ![Status.Deleted].includes(currentReviewStatus) && (
            <div className="col-12 mt-4 mb-4 text-center">
              <Tooltip title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}>
                <Button
                  type="submit"
                  className="d-inline-block px-5"
                  onClick={(e) => { handleUpdateConfirmation(e); }}
                  onMouseDown={(e) => { e.preventDefault(); }}
                  variant="contained"
                  color="primary"
                  disabled={!IsAdminOrContributor(props.page.staffType)}
                  style={{
                    borderRadius: 5,
                    minWidth:     200,
                  }}
                >
                  <Typography variant="body1" color="inherit" style={{ color: Colours.White }}>Save</Typography>
                </Button>
              </Tooltip>
            </div>
          ) }

        </div>
      </div>

      {/**
       * App Preview
       * Need to be a fixed size to adjust the image
      */}
      <div
        id="app-preview"
        className="pt-4 mx-3"
        style={{
          width:       '300px',
          height:      '100vh',
          borderLeft:  '0px solid',
          borderColor: Colours.Gray2,
          position:    'fixed',
          right:       0,
        }}
      >
        <Preview review={props.review} business={props.business} />

        { loading && <Loading />}
      </div>

      <SubscriptionLimitModal
        open={planModal}
        close={() => setPlanModal(false)}
        accountType={accountType}
        page={props.page}
      />

      <Dialog
        fullWidth
        size="sm"
        open={confirmPopup}
        onClose={() => setConfirmPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ dialogContent.title }</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="inherit">{ dialogContent.message }</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUpdate()} color="default" className="mr-4">Proceed</Button>
          <Button onClick={() => setConfirmPopup(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DesktopUpdate.defaultProps = {
  match:    {},
  business: {},
  videos:   [],
  images:   [],
};

DesktopUpdate.propTypes = {
  match:                PropTypes.shape(),
  dispatchReviewUpdate: PropTypes.func.isRequired,
  getReview:            PropTypes.func.isRequired,
  history:              PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push:   PropTypes.func.isRequired,
  }).isRequired,
  business: PropTypes.shape(),
  review:   PropTypes.shape({
    id:              PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title:           PropTypes.string,
    content:         PropTypes.string,
    pageId:          PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    reviewDate:      PropTypes.string,
    expireDate:      PropTypes.string,
    status:          PropTypes.string,
    pageName:        PropTypes.string,
    images:          PropTypes.arrayOf(PropTypes.shape({})),
    videos:          PropTypes.arrayOf(PropTypes.string),
    isFnb:           PropTypes.bool,
    url:             PropTypes.string,
    tags:            PropTypes.arrayOf(PropTypes.string),
    cuisineTags:     PropTypes.arrayOf(PropTypes.string),
    reviewPlaces:    PropTypes.arrayOf(PropTypes.shape({})),
    reviewLocations: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  page: PropTypes.shape({
    staffType: PropTypes.string,
    type:      PropTypes.string,
    status:    PropTypes.string,
    plan:      PropTypes.string,
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      display:  PropTypes.string,
      original: PropTypes.string,
    }),
  ),
  videos: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  business: state.business.selected,
  page:     state.page.selected,
  review:   state.review.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchReviewUpdate: (review) => reviewAction.reviewDataUpdate(dispatch, review),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesktopUpdate);
