/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { IconButton, Button, Stepper, StepLabel, Step, Typography, DialogTitle, DialogContent, DialogActions, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import Loading from '../../../components/Loading';
import Colours from '../../../styles/colours';
import Preview from './Preview';
import Details from './Details/Details';
import Availability from './Availability/Availability';
import Media from './Media';
import Partnerships from './Partnerships/Partnerships';
import Launch from './Launch';
import ApiService from '../../../services/apiService';
import PostAction from '../../../actions/post';
import Constants, { PageType, Status, Error } from '../../../common/constants';
import { IsAdminOrContributor, IfTrueThrowError } from '../../../common/checks';
import { IsEmpty, Trim } from '../../../common/util';
import Tooltip from '../../../components/Common/ToolTip';
import Icons from '../../../components/Common/Icons';
import SubscriptionLimitModal from '../../../components/SubscriptionLimitModal';

const useStyles = makeStyles(() => ({
  root: {
    width:   '100%',
    padding: 0,
  },
  textHeader: {
    color:      Colours.Black,
    fontWeight: 'bold',
  },
  label: {
    margin:     '0 !important',
    cursor:     'pointer',
    fontSize:   14,
    lineHeight: '40px',
  },
}));

const tabMap = {
  Details:      0,
  Availability: 1,
  Media:        2,
  Partnerships: 3,
  Launch:       4,
};

const indexToTabMap = {
  0: 'Details',
  1: 'Availability',
  2: 'Media',
  3: 'Partnerships',
  4: 'Launch',
};

const DesktopUpdate = (props) => {
  const stepperClasses = useStyles();
  const [currentPostStatus, setCurrentPostStatus] = useState(null);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [flagMealsAvailNA, setFlagMealsAvailNA] = useState(false);

  const [planModal, setPlanModal] = useState(false);
  const [accountType, setAccountType] = useState('');
  const [steps, setSteps] = useState(['1. Details', '2. Availability', '3. Media', '4. Partnerships', '5. Launch']);
  const [dialogContent, setDialogContent] = useState({
    title:   'Save to Live',
    message: 'Post cannot be updated back to Draft once it is Live, proceed?',
  });

  useEffect(() => {
    if (!IsEmpty(props.post.flagMealsAvailNA)) {
      setFlagMealsAvailNA(props.post.flagMealsAvailNA || false);
    }
  }, [props.post]);

  const getPostById = async (id) => {
    try {
      const post = await ApiService.getPost(id);
      setCurrentPostStatus(post.status);

      if (post.status === Status.Deleted) {
        setSteps(['1. Details', '2. Availability', '3. Media', '4. Partnerships']);
      }

      let hasMealTags = false;
      if ((post.mealsAvailable.length === 1 && post.mealsAvailable[0] === Constants.NotApplicable) || post.mealsAvailable.length > 1) {
        hasMealTags = true;
      }

      props.dispatchPostUpdate({
        currentPostStatus: post.status,
        flagMealsAvailNA:  hasMealTags,
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    if (props.post.id) {
      getPostById(props.post.id);
    }
  }, [props.post.id]);

  useEffect(() => {
    setAccountType(props.page.plan);
  }, [props]);

  const checkPostData = (post) => {
    IfTrueThrowError(!IsEmpty(post.promoAvailOnlineAt) && !String(post.promoAvailOnlineAt).startsWith('https://'), `Online Purchase URL must start with 'https://'`);
    IfTrueThrowError(post.status === Status.Live && IsEmpty(Trim(post.title)), 'Title is required');
  };

  const setActiveStepAndUrl = (step) => {
    setActiveStep(step);
    const tabName = indexToTabMap[step];
    const params = new URLSearchParams(window.location.search);
    params.set('tab', tabName);
    props.history.replace({ search: params.toString() });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (tab !== null && tabMap[tab] !== undefined) {
      setActiveStep(tabMap[tab]);
    }
  }, []);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const p = props.post;
      const categoryIds = p.categories.map((category) => category.id);

      // Standardize to pass Singapore timezone to the server
      const startDate = p.promoFromDate ? new Date(p.promoFromDate) : null;
      const promoStartDate = startDate ? moment.tz(startDate, 'Asia/Singapore').startOf('day').format('YYYY-MM-DD HH:mm:ss Z') : null;

      const endDate = p.promoTillDate ? new Date(p.promoTillDate) : null;
      const promoEndDate = endDate ? moment.tz(endDate, 'Asia/Singapore').endOf('day').format('YYYY-MM-DD HH:mm:ss Z') : null;

      // perform checks
      checkPostData(p);

      const mealTagsOnly = _.compact(_.map(p.userTags, (tag) => (Constants.MealTags.includes(tag) ? tag : null))) || [];
      const mealsAvailable =  mealTagsOnly.filter((tag) => tag !== Constants.NotApplicable);

      let mealsAvailTags = [Constants.NotApplicable];
      if (!IsEmpty(mealsAvailable) && !mealsAvailable.includes(Constants.NotApplicable)) {
        mealsAvailTags = mealsAvailable;
      } else if (IsEmpty(mealsAvailable) && flagMealsAvailNA === false) {
        mealsAvailTags = [];
      }

      let postStatus = p.status;
      if (props.page.status !== Status.Pending && p.status === Status.Deleted) {
        postStatus = Status.Draft;
      } else if (props.page.status === Status.Pending) {
        postStatus = Status.Pending;
      }

      const payload = {
        title:              p.title,
        description:        p.description,
        status:             postStatus,
        images:             p.images,
        videos:             p.videos,
        categories:         categoryIds,
        isMallHighlight:    p.mallHighlight,
        promoCodes:         p.promoCodes,
        promoType:          p.promoType,
        promoTerms:         p.promoTerms,
        promoFromDate:      promoStartDate,
        promoTillDate:      promoEndDate,
        promoAvailOnlineAt: p.promoAvailOnlineAt ? String(p.promoAvailOnlineAt).trim() : '',
        promoLocations:     p.promoLocations,
        isAvailOnline:      !!p.promoAvailOnlineAt,
        promoIsNationwide:  p.promoIsNationwide,
        promoPartners:      p.promoPartners,
        promoSubsidiaries:  p.promoSubsidiaries,
        promoCreditCards:   p.promoCreditCards,
        promoUrl:           p.promoUrl ? String(p.promoUrl).trim() : '',
        userTags:           p.userTags,
        mealsAvailable:     mealsAvailTags,
      };

      if (![PageType.FNB, PageType.Retailer, PageType.ECommerce].includes(props.page.type)) {
        delete payload.mealsAvailable;
      }

      // eslint-disable-next-line react/prop-types
      await ApiService.updatePost(props.match.params.postId, payload);
      props.dispatchPostUpdate({ currentPostStatus: payload.status });
      toast.success('Post updated');

      setCurrentPostStatus(payload.status);

      props.getPost();
    } catch (err) {
      if (err.message === Error.MaxNumberPublishedPost) {
        setPlanModal(true);
      } else {
        toast.error(err.message);
      }
    } finally {
      setConfirmPopup(false);
      setLoading(false);
    }
  };

  const handleUpdateConfirmation = async (e) => {
    e.preventDefault();
    try {
      const propPost = props.post;

      // check current post status
      const post = await ApiService.getPost(propPost.id);

      setConfirmPopup(true);
      if ((post.status === Status.Paused) && propPost.status === Status.Live) {
        setDialogContent({
          title:   'Save as Live',
          message: `Update post's status from ${post.status} to Live, proceed?`,
        });
      } else if (post.status === Status.Draft && propPost.status === Status.Live) {
        setDialogContent({
          title:   'Save as Live',
          message: `Post cannot be updated back to ${post.status} once it is Live, proceed?`,
        });
      } else if (post.status === Status.Live && propPost.status === Status.Paused) {
        setDialogContent({
          title:   'Save as Paused',
          message: 'Post will not be visible or searchable in the app when the post is Paused. Change will take effect within half an hour. Proceed to pause post?',
        });
      } else {
        setConfirmPopup(false);
        handleUpdate();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (<Details {...props} />);
      case 1:
        return (<Availability {...props} />);
      case 2:
        return (<Media {...props} />);
      case 3:
        return (<Partnerships {...props} />);
      case 4:
        return (<Launch {...props} />);
      default:
    }
  }

  return (
    <>
      <div className="container-fluid d-flex flex-row align-items-start justify-content-between px-0" style={{ position: 'relative' }}>
        <div className="col-lg-9 col-md-9 px-4 pt-1 row flex-column">
          <div className="col-12 d-flex align-items-center">
            <div className="d-inline-flex align-items-center">
              <IconButton style={{ width: 50, height: 50 }} onClick={() => { props.history.goBack(); }}>
                <Icons.ArrowBack colour={Colours.Black} />
              </IconButton>
              <Typography variant="body1" className="ml-2" color="inherit" style={{ color: Colours.Gray }}>Edit Post</Typography>
            </div>

            <div className="ml-auto d-inline-flex align-items-center">
              <Tooltip title={`Post's status`}>
                <Typography variant="body1" style={{ color: Colours.Gray1 }}>
                  Status:&nbsp;
                  <span
                    className="badge badge-pill badge-secondary py-2 px-3 mr-1 ml-2"
                    style={{
                      background:
                      (currentPostStatus === Status.Live)
                        ? Colours.Green
                        : (currentPostStatus === Status.Paused ? Colours.Amber : Colours.Gray1),
                    }}
                  >
                    { currentPostStatus }
                  </span>
                </Typography>
              </Tooltip>
            </div>
          </div>

          <div className="col-12 mt-1 mb-2 d-flex align-items-center justify-content-end">
            <div className="w-100 py-1 px-3 align-items-center text-center" style={{ background: Colours.White, color: Colours.Gray2, border: '1px solid', borderColor: Colours.Gray3, borderRadius: 100 }}>
              <div className="container">
                <div className="row">
                  <Stepper classes={{ root: stepperClasses.root }} nonLinear activeStep={activeStep} connector={<></>} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step key={label}>
                        <StepLabel
                          onClick={() => { setActiveStepAndUrl(index); }}
                          icon={false}
                          classes={{ label: stepperClasses.label }}
                        >
                          { label }
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 d-flex flex-column align-items-end">
            <Typography className="mr-2" variant="caption" color="inherit" style={{ color: Colours.Red }}>* required</Typography>
          </div>

          <div className="col-12 d-block mx-auto mt-1">
            {getStepContent(activeStep)}
          </div>

          { ![Status.Deleted].includes(currentPostStatus) && (
            <div className="col-12 mt-4 mb-4 text-center">
              <Tooltip title={IsAdminOrContributor(props.page.staffType) ? '' : 'Permission Denied'}>
                <Button
                  type="submit"
                  className="d-inline-block px-5"
                  onClick={(e) => { handleUpdateConfirmation(e); }}
                  onMouseDown={(e) => { e.preventDefault(); }}
                  variant="contained"
                  color="secondary"
                  disabled={!IsAdminOrContributor(props.page.staffType)}
                  style={{
                    borderRadius: 5,
                    minWidth:     200,
                  }}
                >
                  <Typography variant="body1" color="inherit" style={{ color: Colours.White }}>Save</Typography>
                </Button>
              </Tooltip>
            </div>
          ) }

        </div>
      </div>

      {/**
       * App Preview
       * Need to be a fixed size to adjust the image
      */}
      <div
        id="app-preview"
        className="pt-3 mx-3"
        style={{
          width:       '300px',
          height:      '100vh',
          borderLeft:  '0px solid',
          borderColor: Colours.Gray2,
          position:    'fixed',
          right:       0,
        }}
      >
        <Preview post={props.post} business={props.business} />

        { loading && <Loading />}
      </div>

      <SubscriptionLimitModal
        open={planModal}
        close={() => setPlanModal(false)}
        accountType={accountType}
        page={props.page}
        post={props.post}
      />

      <Dialog
        fullWidth
        size="sm"
        open={confirmPopup}
        onClose={() => setConfirmPopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{ dialogContent.title }</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" color="inherit">{ dialogContent.message }</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUpdate()} color="default" className="mr-4">Proceed</Button>
          <Button onClick={() => setConfirmPopup(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

DesktopUpdate.defaultProps = {
  match:    {},
  business: {},
  videos:   [],
  images:   [],
};

DesktopUpdate.propTypes = {
  match:              PropTypes.shape(),
  dispatchPostUpdate: PropTypes.func.isRequired,
  getPost:            PropTypes.func.isRequired,
  history:            PropTypes.shape({
    goBack:  PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  business: PropTypes.shape(),
  post:     PropTypes.shape({
    id:                 PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title:              PropTypes.string,
    description:        PropTypes.string,
    brand:              PropTypes.string,
    promoFromDate:      PropTypes.string,
    promoTillDate:      PropTypes.string,
    status:             PropTypes.string,
    categories:         PropTypes.arrayOf(PropTypes.shape({})),
    images:             PropTypes.arrayOf(PropTypes.shape({})),
    videos:             PropTypes.arrayOf(PropTypes.string),
    mallHighlight:      PropTypes.bool,
    promoCodes:         PropTypes.arrayOf(PropTypes.string),
    promoType:          PropTypes.string,
    promoTerms:         PropTypes.string,
    promoAvailOnlineAt: PropTypes.string,
    promoLocations:     PropTypes.arrayOf(PropTypes.shape({})),
    promoIsNationwide:  PropTypes.bool,
    promoPartners:      PropTypes.arrayOf(PropTypes.shape({})),
    promoSubsidiaries:  PropTypes.arrayOf(PropTypes.shape({})),
    promoCreditCards:   PropTypes.arrayOf(PropTypes.shape({})),
    promoUrl:           PropTypes.string,
    userTags:           PropTypes.arrayOf(PropTypes.string),
    mealsAvailable:     PropTypes.arrayOf(PropTypes.string),
    flagMealsAvailNA:   PropTypes.bool,
  }).isRequired,
  page: PropTypes.shape({
    staffType: PropTypes.string,
    type:      PropTypes.string,
    status:    PropTypes.string,
    plan:      PropTypes.string,
  }).isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      display:  PropTypes.string,
      original: PropTypes.string,
    }),
  ),
  videos: PropTypes.arrayOf(PropTypes.string),
};

const mapStateToProps = (state) => ({
  business: state.business.selected,
  page:     state.page.selected,
  post:     state.post.selected,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchPostUpdate: (post) => PostAction.postDataUpdate(dispatch, post),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DesktopUpdate);
